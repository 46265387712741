import {Image, StepKey} from '@/features/photoset/types'
import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {GalleryModalSidebar} from '@/components/commons/gallery-modal-sidebar/GalleryModalSidebar'
import {useTranslation} from 'react-i18next'
import {useGalleryCurationShortcuts} from '@/features/photoset/hooks/useGalleryCurationShortcuts'
import {StyledGalleryModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-curation-modal/style'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {Button} from '@/components/ui/button/Button'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {GalleryMetadata} from '@/features/photoset/components/gallery-photoset-modal/gallery-metadata/GalleryMetadata'
import {GalleryFlags} from '@/features/photoset/components/gallery-photoset-modal/gallery-flags/GalleryFlags'

type GalleryCurationModal = {
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
    currentSpaceId?: number // This is optional to enable the use of component in Curation V1
}

export const GalleryCurationModal: FC<GalleryCurationModal> = ({
    selectableImages,
    currentDetailImageIndex,
    setCurrentDetailImageIndex,
    onClose,
    currentSpaceId
}) => {
    const {t} = useTranslation()
    const photosetSpaceImageSelectionToggle = usePhotosetStore(state => state.photosetSpaceImageSelectionToggle)
    const toggleSelectImage = usePhotosetStore(state => state.toggleSelectImage)
    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const photosetSpaces = usePhotosetStore(state => state.photosetSpaces)

    const currentImage = useMemo(
        () => selectableImages[currentDetailImageIndex],
        [currentDetailImageIndex, selectableImages]
    )

    const currentSpaceIndex = useMemo<number | null>(
        () =>
            currentSpaceId != undefined && currentSpaceId > 0
                ? photosetSpaces.findIndex(space => space.id == currentSpaceId)
                : null,
        [currentSpaceId, photosetSpaces]
    )

    useGalleryCurationShortcuts({
        currentDetailImageIndex,
        currentSpaceIndex,
        setCurrentDetailImageIndex,
        currentImage,
        selectableImages,
        onClose
    })

    return (
        <StyledGalleryModal
            currentImage={currentImage}
            thumbnails={currentImage.thumbnails}
            onClose={onClose}
            headerSlot={
                <GalleryModalHeader
                    currentImage={currentImage}
                    imagesCount={selectableImages.length}
                    onClose={onClose}
                    endSlot={
                        currentImage.thumbnails?.xs && (
                            <Button
                                tabIndex={-1}
                                variant="secondary"
                                onClick={e => {
                                    currentSpaceIndex == null || currentSpaceIndex < 0
                                        ? toggleSelectImage(currentImage.id)
                                        : photosetSpaceImageSelectionToggle(currentSpaceIndex, currentImage.id)
                                    e.currentTarget.blur()
                                }}
                            >
                                <Checkbox
                                    readOnly
                                    tabIndex={-1}
                                    checked={
                                        ((currentSpaceIndex == null || currentSpaceIndex < 0) &&
                                            selectedImages.includes(currentImage.id)) ||
                                        currentImage.is_final_select
                                    }
                                />
                                {t('commons:select_space')}
                            </Button>
                        )
                    }
                />
            }
            thumbnailsSlot={
                <GalleryModalThumbnails
                    currentImage={currentImage}
                    thumbnails={'thumbnails'}
                    images={selectableImages}
                    onSelect={(_, index) => {
                        setCurrentDetailImageIndex(index)
                    }}
                />
            }
            sidebarSlot={
                <GalleryModalSidebar
                    title={t('photoset:curation:sidebar_title')}
                    content={
                        <>
                            <GalleryMetadata imageMetadata={currentImage.metadata} />
                            <GalleryFlags
                                image={currentImage}
                                stepId={StepKey['curation']}
                                // This ternary condition is necessary for Curation V1 to function properly
                                photosetSpaceIds={currentSpaceId ? [currentSpaceId] : []}
                            />
                        </>
                    }
                />
            }
        />
    )
}
